import React, { useState, useEffect } from 'react';
import { fetchQuote } from '../utils/apiUtils';

const QuoteOfTheDay = () => {
    const [quote, setQuote] = useState('');
    const [author, setAuthor] = useState('');

    useEffect(() => {
        const getQuote = async () => {
            try {
                const { text, author } = await fetchQuote();
                setQuote(text);
                setAuthor(author);
            } catch (error) {
                console.error('Failed to fetch quote:', error);
            }
        };

        const interval = setInterval(() => {
            getQuote();
        }, 86400000); // 1 day in milliseconds

        getQuote();
    }, []);

    return (
        <div className="section quote">
            <h1>{quote}</h1>
            <h2>{author}</h2>
        </div>
    );
};

export default QuoteOfTheDay;