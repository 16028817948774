import React, { useState, useEffect } from 'react';
import { fetchLineStatus } from '../tflApi';

const LineStatus = () => {
    const [statuses, setStatuses] = useState([]);

    
    const getStatuses = async () => {
        console.log('Fetching line statuses...');
        const data = await fetchLineStatus();
        setStatuses(data);
    };

    console.log('Setting interval.');
    useEffect(() => {
        getStatuses();
        const interval = setInterval(() => {
            console.log('Running Tube Interval.');

            getStatuses();
        }, 300000); // 3 minutes in milliseconds
    
        return () => clearInterval(interval);
    }, []);
    return (
        <div className="section">
            <h2>Tube Status</h2>
            <p className="last-updated">{new Date().toLocaleTimeString()}</p>
            <ul>
                {statuses.map((status, index) => (
                    <li key={index} className={status.id}>
                      {status.name}: {status.status} 
                      {status.issue && <span>⚠️</span>}
                    </li>
                ))}
            </ul>
        </div>
    );
};




export default LineStatus;