import React, { useState, useEffect } from 'react';
import { fetchWeather, fetchForecast } from '../utils/apiUtils';

const Weather = () => {
    const [weather, setWeather] = useState(null);
    const [forecast, setForecast] = useState([]);

    useEffect(() => {
        const getWeatherData = async () => {
            try {
                const weatherData = await fetchWeather();
                setWeather(weatherData);

                const forecastData = await fetchForecast();
                setForecast(forecastData);
            } catch (error) {
                console.error('Failed to fetch weather data:', error);
            }
        };

        getWeatherData();
        const interval = setInterval(() => {
            getWeatherData();
        }, 300000); // 3 minutes in milliseconds
    }, []);

    if (!weather) {
        return <div>Loading...</div>;
    }

    const getWeatherIcon = (description) => {
        switch (description) {
            case 'clear sky':
                return '☀️';
            case 'few clouds':
                return '🌤️';
            case 'scattered clouds':
                return '☁️';
            case 'broken clouds':
                return '☁️';
            case 'overcast clouds':
                return '☁️';
            case 'light rain':
                return '🌧️';
            case 'light intensity drizzle':
                return '🌧️';
            case 'drizzle':
                return '🌧️';
            case 'shower rain':
                return '🌧️';
            case 'rain':
                return '🌧️';
            case 'thunderstorm':
                return '⛈️';
            case 'snow':
                return '❄️';
            case 'mist':
                return '🌫️';
            default:
                return "❓ " + description;
        }
    };

    return (
        <div className="section">
            <h1 className="weather-header">{Math.round(weather.main.temp)}°C <img src={"https://openweathermap.org/img/wn/" + weather.weather[0].icon + "@2x.png"}/></h1>
            <p className="last-updated">{new Date().toLocaleTimeString()}</p>

            <table className="weather-table">
                <thead>
                    <tr>
                        {forecast.map((day, index) => (
                            <th key={index}>{new Date(day.dt_txt).toLocaleDateString('en-US', { weekday: 'long' })}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {forecast.map((day, index) => (
                            <td key={index}>
                                {Math.round(day.main.temp)}°C<br/> 
                                <img src={"https://openweathermap.org/img/wn/" + day.weather[0].icon + "@2x.png"}/>
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Weather;
