export const TUBE_API_URL = 'https://api.tfl.gov.uk/Line/Mode/tube/Status';
export const OVERGROUND_API_URL = 'https://api.tfl.gov.uk/Line/Mode/overground/Status';
export const ELIZABETH_API_URL = 'https://api.tfl.gov.uk/Line/Mode/elizabeth-line/Status';
const QUOTE_API_URL = '/api/quotes/random';
const API_KEY = 'e5494bec1cd6089cf0f1be4e130a8d7a';
const CURRENT_WEATHER_API_URL = `https://api.openweathermap.org/data/2.5/weather?q=London&appid=${API_KEY}&units=metric`;
const FORECAST_API_URL = `https://api.openweathermap.org/data/2.5/forecast?q=London&appid=${API_KEY}&units=metric`;

export const fetchStatus = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export const fetchQuote = async () => {
    const response = await fetch(QUOTE_API_URL);
    if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
    }
    const data = await response.text();
    const jsonData = JSON.parse(data);
    return {
        text: jsonData.random.text,
        author: jsonData.random.author.name
    };
};

export const fetchWeather = async () => {
    const response = await fetch(CURRENT_WEATHER_API_URL);
    if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
    }
    return response.json();
};

export const fetchForecast = async () => {
    const response = await fetch(FORECAST_API_URL);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.list.filter((reading) => reading.dt_txt.includes("12:00:00"));
};
