import { fetchStatus, TUBE_API_URL, OVERGROUND_API_URL, ELIZABETH_API_URL } from './utils/apiUtils';

export const fetchLineStatus = async () => {
    try {
        const data_tube = await fetchStatus(TUBE_API_URL);
        const lines = data_tube.filter(line => line.id === 'piccadilly' || line.id === 'district');
        
        const data_overground = await fetchStatus(OVERGROUND_API_URL);
        lines.push(...data_overground.filter(line => line.id === 'mildmay'));

        const data_elizabeth = await fetchStatus(ELIZABETH_API_URL);
        lines.push(...data_elizabeth.filter(line => line.id === 'elizabeth'));

        return lines.map(line => ({
            id: line.id,
            name: line.name,
            status: line.lineStatuses[0].statusSeverityDescription,
            issue: line.lineStatuses[0].statusSeverityDescription != "Good Service",
        }));
    } catch (error) {
        console.error('Failed to fetch line status:', error);
        return [];
    }
};