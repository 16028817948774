import React from 'react';
import './App.css';
import Clock from './components/Clock';
import LineStatus from './components/LineStatus';
import Weather from './components/Weather';
import QuoteOfTheDay from './components/QuoteOfTheDay';

const App = () => {
    return (
      <div className="content">
      <Clock />
      <QuoteOfTheDay />
      <LineStatus />
      <Weather />
      </div>
    );
};

export default App;